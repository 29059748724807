import Link from "next/link";
import React from "react";

const Button = ({title, link}) => {
  return (
    <Link href={link}>
      <a className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark">
        {title}
      </a>
    </Link>
  )};

export default Button;
