import Head from "next/head";

const Meta = ({title, desc, keyword, image}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png"/>
        <meta name="description" content={desc}/>
        <meta name="keyword" content={keyword}/>

        <meta property="og:site_name" content="Engineering Book Club"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={desc}/>
        <meta property="og:image" content={image}/>

        <meta name="twitter:card" content={desc}/>
        <meta name="twitter:site" content="@TheEngBookClub"/>
        <meta name="twitter:creator" content="@TheEngBookClub"/>

      </Head>
    </div>
);
};

Meta.defaultProps = {
  title: "Engineering Book Club",
  desc: "The Engineering Book Club is an online community for professionals that wants to read books together.",
  keyword: "engineering, book, club, community, engineers",
  image: "/images/homepage/engineering-books.png"
};

export default Meta;
